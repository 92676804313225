import React from "react";
import "./Homepage.css";
import logo from "../Logo.jpg";
import { Link } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

const Homepage = () => {
  return (
    <div className="homepage">
      <header className="header">
        <img src={logo} className="logo" alt="Boston Luxor Limo." />
        {/* <h1>NOOR LIMOUSINE LLC</h1> */}
      </header>
      <main className="main">
        <div className="innerBox">
          <p className="boxHeading">Welcome to NYC Luxor Limo</p>
          <p className="boxSubHeading">
            Your Trusted Partner for All Your Travel Needs
          </p>
          <div className="buttons">
            <Link to="/iframe">
              <button className="sign-in">Sign In</button>
            </Link>
            <Link to="/iframe">
              <button className="book-now">Book Now</button>
            </Link>
          </div>
          <p className="boxSubHeading" style={{ marginBottom: "15px" }}>
            --------------------- Follow Us ---------------------
          </p>
          <div className="social-media">
            <SocialIcon
              url="https://www.facebook.com/nycluxorlimo"
              style={{ height: 40, width: 40 }}
            />
            <SocialIcon
              url="https://www.instagram.com/nycluxorlimo"
              style={{ height: 40, width: 40 }}
            />
            <SocialIcon
              url="https://x.com/nyc_luxor_limo"
              style={{ height: 40, width: 40 }}
            />
            <SocialIcon
              url="https://www.youtube.com/channel/UC40dO9ZYRv0Kp1brM7SpBOQ"
              style={{ height: 40, width: 40 }}
            />
          </div>
        </div>
        <a
          href="tel:+15168209296"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <button className="call-now"> <FontAwesomeIcon icon={faPhone} /> Call Now</button>
        </a>
      </main>
    </div>
  );
};

export default Homepage;
