import React from 'react';
import IFrame from './IFrame';


function Form() {

  return (
      <IFrame
        src="https://worldlimobiz.com/nycluxorlimo/reservation3-iframe"
        title="Boston Luxor Limo Booking"
        width="100%"
        height="800px"
      />
  );
}

export default Form;
