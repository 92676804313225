import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Homepage from "./Component/Homepage";
import Form from "./Component/Form";
import { useEffect } from "react";

function App() {

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route
            path="/iframe"
            element={<Form/>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
