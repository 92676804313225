import React,{useState} from "react";
import "./IFrame.css"

function IFrame({ src, title, width = "100%", height = "600px" }) {

  const [iframeLoaded, setIframeLoaded] = useState(false);

  const handleLoad = () => {
    setIframeLoaded(true);
  };
  return (
    <>
      <div style={{ background: "#232323", minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      
      {!iframeLoaded && (
        <div className="mobile-loader-container">
          <div className="mobile-loader"></div>
          <p style={{ color: '#fff', marginTop: '10px' }}>Loading...</p>
        </div>
      )}

    <iframe
      src={src}
      title={title}
      width={width}
      height={height}
      onLoad={handleLoad}
      style={{ display: iframeLoaded ? 'block' : 'none' }}
    >
    </iframe>

    </div>
    </>
  );
}

export default IFrame;
